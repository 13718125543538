









































import Vue from "vue";

import { flash } from "../plugins/flashNotifications";
import { mgr } from "../managers";

export default Vue.extend({
  components: {},
  data() {
    return {
      missingStoreCodesHeaders: [
        { text: "Code", value: "storeCode", width: 80 },
        { text: "Store Name", value: "storeName" },
        { text: "Invoice Date", value: "invoiceDate" },
      ],
      incongruousSdtsHeaders: [
        { text: "Delivery Location Name", value: "deliveryLocationName" },
        { text: "Scheduled Arrival Time (SAT)", value: "SAT" },
      ],
      missingStoreCodes: [] as any[],
      incongruousSdts: [] as any[],
    };
  },
  computed: {},
  beforeMount() {
    mgr.alerts.fetch().then((alerts) => {
      this.missingStoreCodes = alerts.missingStoreCodes;
      this.incongruousSdts = alerts.incongruousSdts;
      if (this.missingStoreCodes.length === 0 && this.incongruousSdts.length === 0) flash.info("There are no alerts");
    });
  },
});
