// ------------------------------------------------------------------------
// Application types
// ------------------------------------------------------------------------

export interface iUser {
  id: number;
  username: string;
  email: string;
  superuser?: boolean
  active: boolean;
  organisationId: number;
  organisation: string;
  roles: authRole[];
  lastActiveDate: string;
  apikeyId: number | null;
}

export interface iApikey {
  id: number;
  userId: number;
  active: boolean;
  roles: authRole[];
  createdDate: string;
  lastActiveDate: string;
}

export enum authRole {
  OUTBOUND_SCHEDULER = "outbound-scheduler",
  OUTBOUND_ADMIN = "outbound-admin",
  TRANSPORT_SCHEDULER = "transport-scheduler",
  TRANSPORT_ADMIN = "transport-admin",
  FM = "fleet-manager",
  FM_GUEST = "fleet-manager-guest",
  ASSEMBLY_CLERK = "assembly-clerk",
  ASSEMBLY_CONTROLLER = "assembly-controller",
  ASSEMBLY_ADMIN = "assembly-admin",
  WATSON_ADMIN = "watson-admin",
  WATSON_BUREAU = "watson-bureau",
  GENERIC_USER = "generic-user",
}

// ------------------------------------------------------------------------
// Data types
// ------------------------------------------------------------------------

export enum dayOfWeek {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7,
}

export enum stopType {
  delivery = "delivery",
  backhaul = "backhaul",
  collection = "collection",
}

export enum loadType {
  combo = "combo",
  perish = "perish",
  dry = "dry",
}

export enum storeType {
  delivery = "Delivery",
  inHouse = "Inhouse",
  rail = "Rail",
}

export enum deliveryType {
  scheduled = "scheduled",
  balance = "balance",
  special = "special",
  backhaul = "backhaul",
  collection = "collection",
}


export enum pickerPosition {
  casual = "casual",
  permanent = "permanent"
}
export enum warehouseType {
  dry = "dry",
  perish = "perish"
}
export enum driverScheduleType {
  driving = "driving",
  shunting = "shunting",
  dayOffInLieu = "day_off_in_lieu",
  leaveDay = "leave_day",
  sickDay = "sick_day",
  training = "training",
  birthdayLeaveDay = "birthday_leave_day",
  publicHoliday = "public_holiday"
}

export enum deliveryScheduleType {
  MASTER = "MASTER",
  PEAK_1 = "PEAK_1",
  PEAK_2 = "PEAK_2",
  PEAK_3 = "PEAK_3",
  PEAK_4 = "PEAK_4",
  PEAK_5 = "PEAK_5",
}

export interface iAlert {
  code: number;
  date: string; // ISO date string : 2019-08-18T08:50:39.869Z
}

export interface iStore {
  id: number;
  code: number | undefined;
  name: string;
  storeType: storeType | undefined;
  supplierNumber: number | undefined;
  rom: string | null // Retail Operations Managers name
  active: boolean
  isSupplier: boolean,
  deliveryLocationId: number | undefined;
  deliveryLocationName: string | undefined;
}


export interface iDeliveryLocation {
  id: number;
  name: string;
  longitude: number;
  latitude: number;
  distanceFromDc: number; // km
  targetOffloadTime: number; // seconds
  active: boolean;
  stores: number[];
}

export interface iScheduledDelivery {
  id: number;
  route: number | null;
  deliveryLocationId: number;
  deliveryLocationName: string;
  day: dayOfWeek;
  loadType: loadType;
  sdt: string;
  sat: string;
  stores: number[]
  scheduleType: deliveryScheduleType
}

export interface iDeliveryRoute {
  id: number;
  routeDate: string; //   "2019-11-01"
  route: number;
  fmProvider: string;
  trailerCode: string; // "JMD2"
  driverId: number | null;
  driverName: string | null;
  outsourcedDriverName: string | null;
  scheduledDepartureUtc: string; // "Thu, 27 Feb 2020 07:17:00 GMT"
  departedUtc: string;
  returnedUtc: string;
  distanceKm: number;
}

export interface iDeliveryStop {
  id: number;
  deliveryRouteId: number;
  deliveryLocationId: number;
  stopNum: number;
  loadType: loadType;
  deliveryType: deliveryType;
  stopType: stopType;
  scheduledArrivalUtc: string;
  arrivedUtc: string;
  departedUtc: string;
}

export interface iOffloadEvent {
  id: number;
  deliveryStopId: number;
  startedUtc: string; // "2019-11-01T16:00:00.000"
  endedUtc: string; // "2019-11-01T16:00:00.000"
}

export interface iOffloadContainerGroup {
  id: number;
  offloadEventId: number;
  storeCode: number | undefined;
  supplierNumber: number | undefined;
  totalContainers: number;
  dry: number;
  dryPickByLine: number;
  perish: number;
  freshline: number;
}

export interface iTelemetryReport {
  route: number;
  trailerCode: string;
  fmProvider: string;
  fmProviderId: number;
  distanceKm: number;
  driverId: number;
  driverName: string;
  departedDcUtc: string;
  returnedDcUtc: string;
  deliveryLocation: string;
  arrivedUtc: string;
  departedUtc: string;
  startedUtc: string;
  endedUtc: string;
}


export interface iDriver {
  id: number;
  name: string;
  staffId: number;
  cellNumber: string;
  pdpExpiryDate: string | null
  temporaryPdpActive: boolean | null
  active: boolean;
}

export interface iDriverSchedule {
  id: number;
  date: string;
  driver: iDriver | null;
  scheduleType: driverScheduleType;
  scheduledShiftStartUtc: string;
  shiftStartUtc: string | null;
  shiftEndUtc: string | null;
  returnedUtc: string | null;
  completedShift: boolean;
  scheduledFinalised: boolean;
  reportFinalised: boolean;

  shiftLength: number // seconds
  overtime1: number // seconds
  overtime2: number // seconds
  publicHoliday: number // seconds
  foodAllowance: number
  nightAllowance: number
}

export interface iSmsStatus {
  id: number;
  sid: string; // twilio id
  toName: string;
  toNumber: string;
  content: string;
  status: smsStatus;
  sentUtc: string;
  statusUtc: string;
}

// https://support.twilio.com/hc/en-us/articles/223134347-What-are-the-Possible-SMS-and-MMS-Message-Statuses-and-What-do-They-Mean-
export enum smsStatus {
  accepted = "accepted",
  queued = "queued",
  sending = "sending",
  sent = "sent",
  failed = "failed",
  delivered = "delivered",
  undelivered = "undelivered",
  receiving = "receiving",
  received = "received",
  sendingCancelled = "sending_cancelled", // Sms not send due to being in dev mode
  sendingError = "sending_error", // Error on our side while trying to send an sms
}

export interface iDepartment {
  id: number;
  name: string;
  startDate: string | null;
  endDate: string | null;
  active: boolean;
}


export interface iOrganisation {
  id: number;
  name: string;
  active: boolean;
}

export interface iSection {
  id: number;
  department: iDepartment | null;
  sectionCode: number;
  warehouseCode: number;
  startDate: string | null;
  endDate: string | null;
  active: boolean;
}

export interface iPicker {
  id: number;
  name: string;
  employeeCode: number | null;
  department: iDepartment | null;
  position: pickerPosition;
  active: boolean;

  year?: number;
  month?: number;
  warehouse?: warehouseType;
}

export interface iPickerIncentiveBand {
  id: number;
  startDate: string;
  endDate: string | null;
  bandLowerBound: number;
  bandUpperBound: number | null;
  band: number;
  rate: number;
  department: iDepartment | null;
  active: boolean
}

export interface iPenaltyType {
  id: number
  name: string
  startDate: string;
  endDate: string | null;
  amount: number | null;
  active: boolean
}

export interface iOvertimeType {
  id: number
  name: string
  startDate: string;
  endDate: string | null;
  rate: number
  active: boolean
}
export interface iPickerOvertime {
  id: number
  pickerId: number
  pickerName: string
  pickingPeriod: string
  overtimeTypeId: number
  hours: number
  submitted: boolean
}
export interface iPickerPenaltySimple {
  id: number
  pickerId: number
  pickerName: string
  pickingPeriod: string
  penaltyTypeId: number
  penaltyTypeName: string
  submitted: boolean

  numPenalties: number
}
export interface iPickerPenaltyDetailed {
  id: number
  pickerId: number
  pickerName: string
  pickingPeriod: string
  penaltyTypeId: number
  penaltyTypeName: string
  submitted: boolean

  date: string
  assignmentNumber: number
  storeCode: number
  claimNumber: number | null
  invoiceNumber: number | null
  itemCode: number
  numberOfCases: number
  value: number
  weight: number | null
}


export enum vehicleType {
  vehicle = "vehicle",
  rigid = "rigid",
  horse = "horse",
  trailer = "trailer",
}

export interface iFmProvider {
  id: number;
  name: string;
}

export interface iVehicle {
  id: number;
  type: vehicleType;
  code: string;
  registration: string | null;
  active: boolean;
  externalLength: number | null;
  fmProvider: iFmProvider | null;
}

export interface iRigid extends iVehicle {
  palletteCapacity: number;
  internalWidth: number | null;
  internalHeight: number | null;
  internalLength: number | null;
}
export interface iHorse extends iVehicle { }
export interface iTrailer extends iVehicle {
  palletteCapacity: number;
  internalWidth: number | null;
  internalHeight: number | null;
  internalLength: number | null;
}

export interface iStoreLimitation {
  id: number;
  storeId: number;
  storeName: string;
  rigid: boolean;
  max11M: boolean;
  max13M: boolean;
  max15M: boolean;
  tLift: boolean;
  nonTLift: boolean;
  airBags: boolean;
  groundOffLoading: boolean;
  dockLevelOffLoading: boolean;
  rolltainerOnly: boolean;
}

export interface iTransporter {
  id: number;
  name: string;
  active: boolean;
  createdUtc: string;
}


export interface iIssue {
  obj: string; // name of object
  id: number; // objects id
  key: string; // eg: driverId
  val: any; // eg: null
  msg: string | string[]; // eg: "Missing"
}

export interface iIssueGroup {
  missing: iIssue[]
  errors: iIssue[]
  warnings: iIssue[]
}

export interface iTransporterRouteAgreementAdjustment {
  id: number;
  agreementId: number;
  value: number;
  description: string | null;
  active: boolean;
  createdUtc: string;
}
