












import Vue from "vue";
import store from "./store/store";
import { flash, iNotification } from "./plugins/flashNotifications";

import NavFrame from "./components/NavFrame.vue";
import Notifications from "./components/Notifications.vue";

export default Vue.extend({
  name: "App",
  store,
  components: { NavFrame, Notifications },
  data() {
    return {};
  },
  computed: {
    notifications(): iNotification[] {
      return flash.getAll();
    },
  },
  methods: {},
  beforeMount() {},
  created() {},
});
