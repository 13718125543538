






















import Vue from "vue";
import Stores from "../components/stores/Stores.vue";
import Suppliers from "../components/stores/Suppliers.vue";
import { auth } from "../plugins/auth";
import { authRole } from "../types";

export default Vue.extend({
  name: "Stores",
  components: { Stores, Suppliers },
  props: {},
  data() {
    return {
      currentTab: null,
    };
  },
  computed: {
    tabs() {
      const tabs = [
        { title: "Stores", component: Stores, requiredRoles: [] },
        { title: "Suppliers", component: Suppliers, requiredRoles: [authRole.OUTBOUND_ADMIN] },
      ];
      return tabs.filter((x) => auth.hasRole(x.requiredRoles));
    },
  },
  methods: {},
  beforeMount() {},
});
