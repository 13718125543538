






import Vue from "vue";
import StoreViewer from "./StoreViewer.vue";

export default Vue.extend({
  name: "Suppliers",
  components: { StoreViewer },
  props: {},
  data: () => ({}),
});
